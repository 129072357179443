<template>
  <v-container fluid>
    <div class="mb-5 d-flex justify-end">
      <v-dialog width="900" scrollable persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            :disabled="loading"
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="recuperarProcessosAnteriores"
          >
            <v-icon left>mdi-import</v-icon>
            Importar Salas e Locais de Prova
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-card-title class="primary justify-start white--text">
              Importar Salas e Locais de Prova de Processo Anterior
              <v-spacer />
              <v-btn icon color="white" @click="dialog.value = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <EscolhaProcessoAutocomplete
                  v-if="dialog.value"
                  v-model="processoSelecionadoImportacao"
                  :processos="processosAnteriores"
                />
              </v-container>
              <div class="d-flex justify-center">
                <SideBySideMultiSelect
                  v-if="dialog.value"
                  v-model="locaisEscolhidosParaImportar"
                  :items="locaisProcessoAnterior"
                />
              </div>
              <v-card-actions class="justify-center">
                <v-btn color="error" @click="dialog.value = false"
                  >Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    importarLocaisProva();
                    dialog.value = false;
                  "
                  >Confirmar
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-btn
        class="ml-2"
        small
        :disabled="loading"
        color="primary"
        @click="criarLocalProva"
      >
        <v-icon left>mdi-plus-thick</v-icon>
        Adicionar
      </v-btn>
    </div>
    <v-data-table
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [25, 50, 100, 200, 500],
      }"
      :headers="headers"
      :items="elementos"
      :loading="loading"
      :options.sync="options"
      :expanded.sync="expanded"
      :server-items-length="numTotalElementos"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
      multi-sort
      calculate-widths
      dense
      show-expand
      item-key="oid"
    >
      <template
          v-for="header in headers.filter(h => !h.transient)" v-slot:[`item.${header.value}`]="{ value }"
      >
        {{ defaultValue(header, value) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row justify="center" align="center">
          <v-col cols="4">
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2"
                  color="error"
                  icon
                  x-small
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title class="primary justify-start white--text"
                    >Confirmar Exclusão
                  </v-card-title>
                  <v-card-text
                    style="font-size: 15pt; text-align: center"
                    class="mt-5 font-weight-bold"
                  >
                    Deseja Realmente Excluir este Local de Prova? Esta ação não
                    poderá ser desfeita!
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="primary"
                      @click="
                        excluirLocalProva(item);
                        dialog.value = false;
                      "
                      >Confirmar
                    </v-btn>
                    <v-btn color="error" @click="dialog.value = false"
                      >Cancelar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
          <v-col cols="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="accent"
                  class="mr-3"
                  icon
                  x-small
                  @click="editarLocalProva(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Editar Local de Prova</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="info"
                    class="mr-3"
                    icon
                    x-small
                    @click="listarSalasLocalProva(item)"
                >
                  <v-icon> mdi-chair-school </v-icon>
                </v-btn>
              </template>
              <span>Salas</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="my-1" justify="space-around">
            <v-col cols="3">
              <v-card outlined>
                <v-card-title class="subtitle-2"> Endereço </v-card-title>
                <v-card-text class="mt-n4">
                  <tr>
                    {{
                      item.endereco || "---"
                    }}
                  </tr>
                  <tr>
                    {{
                    item.bairro || "---"
                    }}
                  </tr>
                  <tr>
                    {{
                      item.localizacao || "---"
                    }}
                  </tr>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card outlined>
                <v-card-title class="subtitle-2"> Referência </v-card-title>
                <v-card-text class="mt-n4">
                  <tr>
                    {{
                      item.referencia || "---"
                    }}
                  </tr>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-if="dialog" v-model="dialog" width="70%" persistent scrollable>
      <v-card>
        <v-card-title class="primary justify-start white--text">
          {{
            isCriandoLocalProva
              ? "Novo Local de Prova"
              : "Atualizar Local de Prova"
          }}
          <v-spacer />
          <v-btn icon color="white" @click="cancelarEdicaoLocalProva">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="edicaoLocalProvaForm" @submit.prevent="salvarLocalProva">
            <v-card flat>
              <v-card-text>
                <v-row dense>
                  <v-col cols="8">
                    <v-text-field
                      v-model="localProvaEditado.nome"
                      :rules="[campoObrigatorioRule]"
                      dense
                      label="Nome *"
                      name="localProvaName"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="localProvaEditado.codigo"
                      :rules="[campoObrigatorioRule]"
                      dense
                      label="Código *"
                      name="localProvaCodigo"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="localProvaEditado.turno"
                      dense
                      :items="turnos"
                      label="Turno *"
                      :rules="[campoObrigatorioRule]"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="localProvaEditado.diaProva"
                      :items="dias"
                      dense
                      label="Dia de Prova *"
                      :rules="[campoObrigatorioRule]"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      ref="startMenu"
                      :close-on-content-click="false"
                      :return-value.sync="localProvaEditado.dataHora"
                      offset-y
                      min-width="200px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formatDate(localProvaEditado.dataHora)"
                          label="Data/Hora *"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :rules="[campoObrigatorioRule]"
                        ></v-text-field>
                      </template>
                      <v-card>
                        <vc-date-picker
                          color="green"
                          mode="dateTime"
                          is24hr
                          locale="pt-BR"
                          :modelConfig="{ timeAdjust: '12:00:00' }"
                          v-model="localProvaEditado.dataHora"
                          no-title
                          scrollable
                        ></vc-date-picker>
                        <v-spacer></v-spacer>
                        <v-card-actions class="justify-lg-space-around">
                          <v-btn
                            color="error"
                            @click="$refs.startMenu.isActive = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            @click="
                              $refs.startMenu.save(localProvaEditado.dataHora)
                            "
                          >
                            OK
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="localProvaEditado.endereco"
                      :rules="[campoObrigatorioRule]"
                      dense
                      label="Endereço *"
                      name="localProvaEndereco"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="localProvaEditado.bairro"
                      dense
                      label="Bairro *"
                      :rules="[campoObrigatorioRule]"
                      name="localProvaBairro"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="localProvaEditado.cep"
                      :rules="[campoObrigatorioRule]"
                      dense
                      v-mask="'########'"
                      max-length="8"
                      label="CEP *"
                      name="localProvaCEP"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="localProvaEditado.estado"
                      dense
                      label="Estado *"
                      :rules="[campoObrigatorioRule]"
                      name="localProvaEstado"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="localProvaEditado.cidade"
                      dense
                      label="Cidade *"
                      :rules="[campoObrigatorioRule]"
                      name="localProvaCidade"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="localProvaEditado.capacidadeTotal"
                      :rules="[campoObrigatorioRule]"
                      dense
                      label="Capacidade Total *"
                      name="localProvaCapacidadeTotal"
                      outlined
                      validate-on-blur
                    />
                  </v-col>
                  <v-col cols="3" class="pa-3" v-if="!isCriandoLocalProva">
                    <span>
                      Estimativa de Capacidade Total:
                      {{ localProvaEditado.estimaCapacidadeTotal }}
                    </span>
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="localProvaEditado.referencia"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Ponto de Referência *"
                  name="localProvaPontoReferencia"
                  outlined
                  validate-on-blur
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click.prevent="cancelarEdicaoLocalProva">
                  Cancelar
                </v-btn>
                <v-btn color="primary" type="submit"> Salvar </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialogSalas"
      v-model="dialogSalas"
      width="85%"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary justify-start white--text">
          {{ localProvaEscolhido.nome }}
          <v-spacer />
          <v-btn icon color="white" @click="dialogSalas = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <sala-local-prova
              :salas-local-prova="salasLocalProva"
              :entidade-oid="localProvaEscolhido.oid"
              @excluirSalaLocalProva="excluirSalaLocalProva"
              @atualizarSalas="atualizarSalas"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import localProvaService from "@/services/localProva.service";
import { mapActions, mapState } from "vuex";
import luxon from "@/core/luxon";
import rules from "@/commons/rules";
import processoService from "@/services/processo.service";
import SideBySideMultiSelect from "@/components/base/SideBySideMultiSelect";
import EscolhaProcessoAutocomplete from "@/components/base/EscolhaProcessoAutocomplete";
import salaLocalProvaService from "@/services/salaLocalProva.service";
import SalaLocalProva from "@/components/pages/admin/processos/SalaLocalProva";

export default {
  name: "LocalProva",
  components: {
    SalaLocalProva,
    SideBySideMultiSelect,
    EscolhaProcessoAutocomplete,
  },
  data() {
    return {
      numTotalElementos: 0,
      elementos: [],
      dialog: false,
      dialogSalas: false,
      loading: false,
      options: {},
      headers: [
        { text: "", value: "data-table-expand", transient: true },
        { text: "Código", value: "codigo" },
        { text: "Nome", value: "nome" },
        { text: "Turno", value: "turno" },
        { text: "Dia de Prova", value: "diaProva", align: "center" },
        { text: "Data/Hora", value: "dataHora", align: "center"},
        { text: "Capacidade Total", value: "capacidadeTotal", align: "center" },
        { text: "Ações", value: "actions", sortable: false, align: "center", transient: true },
      ],
      sortBy: ["nome"],
      sortDesc: [false],
      expanded: [],
      paramsFiltro: {},
      paramsFiltroSetado: false,
      localProvaEditado: {},
      turnos: ["Manhã", "Tarde", "Noite", "Integral"],
      dias: [1, 2],
      salasLocalProva: [],
      localProvaEscolhido: {},
      processoSelecionadoImportacao: {},
      processosAnteriores: [],
      locaisEscolhidosParaImportar: [],
      locaisProcessoAnterior: [],
    };
  },

  created() {
    this.paramsFiltro = {
      processoOid: this.processoSelecionado.oid,
    };
  },

  async mounted() {
    let loader = this.$loading.show();

    this.paramsFiltroSetado = true;

    await this.getDataFromApi();

    loader.hide();
  },

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"]),

    isCriandoLocalProva() {
      return !this.localProvaEditado.oid;
    },
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    defaultValue(header, value) {
      if (luxon.dataValida(value)) {
        return this.formatDate(value);
      }

      if (typeof value !== "number" && !value) {
        header.align = "center";
        return "-";
      }

      return value;
    },

    async recuperarProcessosAnteriores() {
      this.locaisProcessoAnterior = [];
      await processoService
        .recuperarProcessosConsultaPorTipoProcessoCodigo(
          this.processoSelecionado.tipoProcesso.codigo
        )
        .then((response) => {
          this.processosAnteriores = response.data;
        });
    },

    criarLocalProva() {
      this.localProvaEditado = {};
      this.dialog = true;
    },

    async editarLocalProva(localProva) {
      await localProvaService.getLocalProvaByOid(localProva.oid)
        .then((response) => {
          this.localProvaEditado = response.data;
        });
      this.dialog = true;
    },

    async getDataFromApi() {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({ direcao: +sortDesc[i], coluna: sortBy[i] });
      }

      await localProvaService
        .recuperarFiltro(this.paramsFiltro, page - 1, itemsPerPage, ordenacao)

        .then((response) => {
          this.elementos = response.data.elementos;
          this.numTotalElementos = response.data.numTotalElementos;
        });
      this.loading = false;
    },

    formatDate(data) {
      if (data === undefined) {
        return "";
      }
      return luxon.dataConverter(data).toFormat("dd/LL/yyyy HH:mm");
    },

    cancelarEdicaoLocalProva() {
      this.localProvaEditado = {};
      this.$refs.edicaoLocalProvaForm.reset();
      this.dialog = false;
    },

    async salvarLocalProva() {
      if (this.$refs.edicaoLocalProvaForm.validate()) {
        let loader = this.$loading.show();

        if (this.isCriandoLocalProva) {
          await localProvaService.criarLocalProva(this.localProvaEditado, this.processoSelecionado.oid)
            .then(() => {
              this.getDataFromApi();
              this.exibirAviso({
                mensagem: "Local Prova cadastrado com sucesso",
                tipo: "success",
              });
            })
            .catch((error) => {
              let mensagem = "Erro ao cadastrar Local de Prova";
              if (error.response.status === 409) {
                mensagem += ": " + error.response.data.msg;
              }
              this.exibirAviso({
                mensagem: mensagem,
                tipo: "error",
              });
            });
        } else {
          await localProvaService.atualizarLocalProva(this.localProvaEditado)
            .then(() => {
              this.getDataFromApi();
              this.exibirAviso({
                mensagem: "Local Prova atualizado com sucesso",
                tipo: "success",
              });
            })
            .catch((error) => {
              let mensagem = "Erro ao atualizar os dados do Local de Prova";
              if (error.response.status === 409) {
                mensagem += ": " + error.response.data.msg;
              }
              this.exibirAviso({
                mensagem: mensagem,
                tipo: "error",
              });
            });
        }

        this.cancelarEdicaoLocalProva();

        loader.hide();
      }
    },

    async importarLocaisProva() {
      let loader = this.$loading.show();

      await localProvaService
        .importarLocaisProva(
          this.locaisEscolhidosParaImportar,
          this.processoSelecionado.oid
        )
        .then(() => {
          console.log("No await");
          this.getDataFromApi();
        })
        .catch((error) => {
          let mensagem = "Erro ao importar Locais de Prova";
          if (error.response.status === 409) {
            mensagem += ": " + error.response.data.msg;
          }
          this.exibirAviso({
            mensagem: mensagem,
            tipo: "error",
          });
        });

      loader.hide();
    },

    async excluirLocalProva(localProva) {
      let loader = this.$loading.show();

      await localProvaService.excluirLocalProva(localProva.oid)
        .then(() => {
          this.getDataFromApi();
        })
        .catch((error) => {
          let mensagem = "Erro ao excluir Local de Prova";
          if (error.response.status === 409) {
            mensagem += ": " + error.response.data.msg;
          }
          this.exibirAviso({
            mensagem: mensagem,
            tipo: "error",
          });
        });

      loader.hide();
    },

    async listarSalasLocalProva(localProva) {
      let loader = this.$loading.show();

      await salaLocalProvaService.recuperarSalasLocalProvaPorLocalProva(localProva.oid).then((response) => {
        this.salasLocalProva = response.data ?? [];
        this.localProvaEscolhido = localProva;
      });

      this.dialogSalas = true;

      loader.hide();
    },

    async atualizarSalas(oid) {
      await salaLocalProvaService.recuperarSalasLocalProvaPorLocalProva(oid).then((response) => {
        this.salasLocalProva = response.data ?? [];
      });
    },

    async excluirSalaLocalProva(salaLocalProva) {
      let loader = this.$loading.show();
      await salaLocalProvaService.excluirSalaLocalProva(salaLocalProva.oid).then(() => {
        this.salasLocalProva.splice(this.salasLocalProva.indexOf(salaLocalProva), 1);
        this.exibirAviso({
          mensagem: "Sala excluída com sucesso",
          tipo: "success"
        });
      }).catch((error) => {
        let mensagem = "Erro ao excluir Sala";
        if (error.response.status === 409) {
          mensagem += ": " + error.response.data.msg;
        }
        this.exibirAviso({
          mensagem: mensagem,
          tipo: "error"
        });
      });

      loader.hide();
    },
  },

  watch: {
    processoSelecionado: {
      handler() {
        window.scrollTo({ top: 0, behavior: "smooth" });

        this.getDataFromApi();
      },
      deep: true,
    },

    processoSelecionadoImportacao: {
      async handler() {
        if (this.processoSelecionadoImportacao.oid) {
          const loader = this.$loading.show();
          await localProvaService.recuperarLocaisProvaPorProcesso(this.processoSelecionadoImportacao.oid)
            .then((response) => {
              this.locaisProcessoAnterior = response.data;
            }).catch(() => {});

          loader.hide();
        } else {
          this.locaisProcessoAnterior = [];
        }
      },
    },

    options: {
      handler() {
        if (this.paramsFiltroSetado) {
          window.scrollTo({ top: 0, behavior: "smooth" });

          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>